{/* eslint-disable i18next/no-literal-string */}
const InputStyles = {
  baseStyle: {
    addon: {
      h: "2rem",
      height: "2rem",
      borderRadius: "none",
      rounded: "none",
    },
    field: {
      color: "textDark",
      fontFamily: "body",
      borderRadius: "none",
      rounded: "none",
      h: "2rem",
      height: "2rem",
    },
  },
};

export default InputStyles;
