import { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { SessionProvider, useSession } from "next-auth/react";
import { ChakraProvider } from "@chakra-ui/react";
import { NextIntlProvider } from "next-intl";
import type { AppProps } from "next/dist/shared/lib/router/router";
import { useApollo } from "../src/lib/api/graphql/apolloClient";
import { LocalesProvider } from "src/contexts/LocalesContext";
import theme from "../src/lib/theme/theme";
import "../styles/globals.css";
import "react-quill/dist/quill.snow.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UserProvider } from "lib/contexts/useUser";
import LoadingPage from "pages/LoadingPage";
import appVersion from "../version";
import { FALLBACK_LANGUAGE } from "lib/utils/constants";

const AppWithHocs = ({
  Component,
  router: { locale },
  pageProps: {
    messages,
    initialApolloState,
    ...pageProps
  },
}: AppProps) => {
  const apolloClient = useApollo(initialApolloState);
  const { data, status } = useSession();

  useEffect(() => {
    // eslint-disable-next-line
    console.info("myHeart " + (appVersion.version || "dev") + " - " + (appVersion.commit || "no commit"))
  }, []);

  if (status === "loading" && !data) {
    return <LoadingPage />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <ChakraProvider theme={theme}>
        <NextIntlProvider messages={messages}>
          <LocalesProvider currentLocale={locale ?? FALLBACK_LANGUAGE}>
            <UserProvider>
              <Component {...pageProps} />
            </UserProvider>
          </LocalesProvider>
        </NextIntlProvider>
      </ChakraProvider>
    </ApolloProvider>
  );
};

const AppWithSession = ({ Component, pageProps: { session, ...pageProps }, ...rest }: AppProps) => (
  <SessionProvider session={session} refetchOnWindowFocus={true}>
    <AppWithHocs
      Component={Component}
      pageProps={pageProps}
      {...rest}
    />
  </SessionProvider>
);

export default AppWithSession;
