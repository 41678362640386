import { ApolloClient, HttpLink, split } from "@apollo/client";
import type { NormalizedCacheObject } from "@apollo/client/cache";
import { InMemoryCache } from "@apollo/client/cache";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import fetch from "isomorphic-unfetch";
import ws from "isomorphic-ws";

import React from "react";

import { SubscriptionClient } from "subscriptions-transport-ws";
import { IfAdd } from "../../conditional-expanders/conditionalExpanders";
import { useSession } from "next-auth/react";

const createHttpLink = (token: string) => {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_API_HTTPS_URL,
    credentials: "include",
    headers: {
      ...IfAdd(token, { Authorization: `Bearer ${token}` }),
    },
    fetch,
  });
  return httpLink;
};

const createWSLink = (token: string) => {
  return new WebSocketLink(
    new SubscriptionClient(
      process.env.NEXT_PUBLIC_API_WSS_URL || "",
      {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          return {
            headers: {
              ...IfAdd(token, { Authorization: `Bearer ${token}` }),
            },
          };
        },
      },
      ws
    )
  );
};

let apolloClient: ApolloClient<NormalizedCacheObject>;
let ssrApolloClient: ApolloClient<NormalizedCacheObject>;

export const createApolloClient = (token: string) => {
  const ssrMode = typeof window === "undefined";

  const link = !ssrMode
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        createWSLink(token),
        createHttpLink(token)
      )
    : createHttpLink(token);

  return new ApolloClient({ ssrMode, link, cache: new InMemoryCache() });
};

export const initializeApollo = (initialState = {}, token: any = "") => {
  const _apolloClient =
    (typeof window === "undefined" ? ssrApolloClient : apolloClient) ??
    createApolloClient(token || "");

  if (initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === "undefined") {
    if (!ssrApolloClient) {
      ssrApolloClient = _apolloClient;
    }
    return _apolloClient;
  }

  return _apolloClient;
};

export function useApollo(initialState: any) {
  const { data: session } = useSession();
  const store = React.useMemo(() => {
    return initializeApollo(initialState, session?.accessToken);
  }, [initialState, session?.accessToken]);
  return store;
}
