{/* eslint-disable i18next/no-literal-string */}
const SelectStyles = {
  baseStyle: {
    field: {
      color: "textDark",
      borderRadius: "none",
      rounded: "none",
      h: "2rem",
      height: "2rem",
    },
  },
};

export default SelectStyles;
