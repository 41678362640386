{/* eslint-disable i18next/no-literal-string */}
import { extendTheme } from "@chakra-ui/react";
import Accordion from "./components/AccordionStyles";
import Form from "./components/FormStyles";
import FormLabel from "./components/FormLabelStyles";
import Heading from "./components/HeadingStyles";
import ModalHeader from "./components/ModalHeaderStyles";
import Input from "./components/InputStyles";
import Button from "./components/ButtonStyles";
import Menu from "./components/MenuStyles";
import Text from "./components/TextStyles";
import Table from "./components/TableStyles";
import Textarea from "./components/TextareaStyles";
import Select from "./components/SelectStyles";
import Skeleton from "./components/SkeletonStyles";

export default extendTheme({
  fonts: {
    body: "'Montserrat', sans-serif",
    heading: "'Montserrat', sans-serif",
  },
  colors: {
    textDark: "#333333",
    textGray: "#878787",
    textMuted: "#B0B0B0",

    lightGray: "#DADADA",
    lightestGray: "#EDEDED",

    brandOrange: {
      50: "#fbdcb3",
      100: "#f8c480",
      200: "#f5ac4d",
      300: "#f3a133",
      400: "#f2951a",
      500: "#F08900",
      600: "#d87b00",
      700: "#c06e00",
      800: "#a86000",
      900: "#905200",
    },
    brandBlue: {
      50: "#b3d6dc",
      100: "#80bbc5",
      200: "#4d9fae",
      300: "#3391a2",
      400: "#1a8497",
      500: "#00768B",
      600: "#006a7d",
      700: "#005e6f",
      800: "#005361",
      900: "#004753",
    },
    homeSectionBlue: "#649FAD",
  },
  styles: {
    global: {
      body: {
        bg: "white",
        color: "textGray",
      },
      // styles for the `a`
      "a:not(.chakra-linkbox__overlay)": {
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
  components: {
    Accordion,
    Button,
    FormLabel,
    Form,
    Heading,
    ModalHeader,
    Input,
    Textarea,
    Skeleton,
    Select,
    Menu,
    Table,
    Text,
  },
});
