import { CircularProgress } from "@chakra-ui/progress";
import { Center } from "@chakra-ui/react";

import React from "react";

const LoadingPage = () => (
  <Center w={"100%"} h={"100vh"} flexGrow={1}>
    <CircularProgress color="brandOrange.500" isIndeterminate />
  </Center>
);

export default LoadingPage;
