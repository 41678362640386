let appVersion = {
  version: "dev",
  commit: "undefined"
};

try {
  appVersion = require("./version.json");
} catch (_) {
  // no problem
}

export default appVersion;
