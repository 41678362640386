{/* eslint-disable i18next/no-literal-string */}
const MenuStyles = {
  parts: ["menu"],
  baseStyle: {
    menu: {
      px: 10,
    },
  },
  sizes: {
    md: {
      menu: {
        w: "350px",
        minW: "350px",
      },
    },
    lg: {
      menu: {
        w: "450px",
        minW: "450px",
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default MenuStyles;
