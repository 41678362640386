{/* eslint-disable i18next/no-literal-string */}
const TextStyles = {
  variants: {
    dark: {
      color: "textDark",
    },
    muted: {
      color: "textMuted",
    },
    white: {
      color: "white",
    },
    error: {
      color: "red.500",
    },
    sectionLabel: {
      textTransform: "uppercase",
      fontSize: "xs",
      fontWeight: "bold",
      letterSpacing: 1,
    },
  },
};

export default TextStyles;
