{/* eslint-disable i18next/no-literal-string */}
const ButtonStyles = {
  baseStyle: {
    borderRadius: 0,
    textTransform: "uppercase",
    color: "white",
    fontWeight: "bold",
    fontSize: "sm",
  },
  sizes: {
    lg: {
      h: 12,
      minW: 12,
      fontSize: "md",
      px: 8,
    },
    xl: {
      h: 16,
      minW: 16,
      fontSize: "md",
      px: 12,
    },
  },
};

export default ButtonStyles;
