{/* eslint-disable i18next/no-literal-string */}
const FormStyles = {
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            transform: "scale(0.85) translateY(-22px) translateX(-10px)",
          },
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
          {
            transform: "scale(0.85) translateY(-22px) translateX(-10px)",
          },
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          backgroundColor: "gray.50",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          my: 2,
        },
      },
    },
  },
};

export default FormStyles;
