{/* eslint-disable i18next/no-literal-string */}
type ID = string | number;

export const getPaths = {
  index: {
    home: () => "/",
    sections: {
      sports: {
        url: () => "/#sports",
        id: () => "sports",
      },
      coaches: {
        url: () => "/#coaches",
        id: () => "coaches",
      },
      healthProfessionals: {
        url: () => "/#healthProfessionals",
        id: () => "healthProfessionals",
      },
      mission: {
        url: () => "/#mission",
        id: () => "mission",
      },
      reviews: {
        url: () => "/#reviews",
        id: () => "reviews",
      },
    }
  },
  login: () => "/sign_in",
  register: {
    coach: {
      step: (step: number, p?: string) => `/register/coach?step=${step}${p ? `&${p}` : ""}`,
    },
    athlete: {
      step: (step: number) => `/register/athlete?step=${step}`,
      stepAndParams: (step: number, coachId?: ID, sportId?: ID, subscriptionPlanId?: ID) => {
        let gotoUrl = `/register/athlete?step=${step}`;
        if (coachId) gotoUrl += `&coachId=${coachId}`;
        if (sportId) gotoUrl += `&sportId=${sportId}`;
        if (subscriptionPlanId) gotoUrl += `&subscriptionPlanId=${subscriptionPlanId}`;
        return gotoUrl;
      },
    },
  },
  recoverPassword: () => "/recover_password",
  setPassword: () => "/set_password",
  athlete: {
    index: () => "/athlete",
    challenges: () => "/athlete/challenges",
    progress: () => "/athlete/progress",
    profile: () => "/athlete/profile",
    health: () => "/athlete/health",
    training: () => "/athlete/training",
    date: (date: string) => `/athlete/?date=${date}`,
  },
  sport: {
    id: (id: ID) => `/sport/${id}`,
  },
  coaches: {
    id: (id: ID) => `/coaches/${id}`,
  },
  termsOfService: () => "/tos",
};
