{/* eslint-disable i18next/no-literal-string */}
const TextareaStyles = {
  baseStyle: {
    color: "textDark",
    borderRadius: "none",
    rounded: "none",
  },
};

export default TextareaStyles;
