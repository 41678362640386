import { signOut as signOutOG } from "next-auth/react";
import { useApollo } from "lib/api/graphql/apolloClient";
import type { IAthleteBaseInfoFragment } from "lib/api/sdk";
import { AthleteBaseInfoDocument, AthleteHasAccessDocument } from "lib/api/sdk";
import { useSession } from "next-auth/react";
import type { ReactNode } from "react";
import React, { createContext, useCallback, useMemo, useState, useEffect, useContext } from "react";
import { getPaths } from "lib/utils/paths";
import LoadingPage from "pages/LoadingPage";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import useTranslationConstants from "lib/utils/constants";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

const UserContext = createContext({} as UserContextValues);
const { Provider } = UserContext;

type UserData = {
  user_id?: number
  user_name?: string
  athlete?: IAthleteBaseInfoFragment["athlete"]
  stravaAccessToken?: string
  hasAccess?: boolean
}

type UserContextValues = {
  loggedIn: boolean
  loading: boolean
  updateUser: () => Promise<void>
  signOut: () => Promise<void>
} & UserData

const JWT_REFRESH_INTERVAL = 1000 * 60 * (__DEV__ ? 1 : 5); // 5 minutes (1 minute if dev)

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { data: session, update: updateSession } = useSession();
  const [user, setUser] = useState<UserData>();
  const [loading, setLoading] = useState(true);
  const apolloClient = useApollo({});
  const t = useTranslations();
  const { locale, push } = useRouter();
  const { LINKS } = useTranslationConstants();

  const updateUser = useCallback(async () => {
    setLoading(true);
    const res = await apolloClient.query({
      query: AthleteBaseInfoDocument,
      fetchPolicy: "network-only",
    });
    const user = res.data?.user?.[0];

    if (!user) {
      signOut();
      return;
    }

    const hasAccessRes = await apolloClient.query({
      query: AthleteHasAccessDocument,
      variables: { athlete_id: user.athlete?.id },
      fetchPolicy: "network-only"
    });
    const hasAccess = hasAccessRes.data?.check_if_athlete_has_access.success || false;

    setUser({
      user_id: user.id,
      user_name: user.name,
      athlete: user.athlete,
      stravaAccessToken: user.strava_access_token,
      hasAccess,
    });
    setLoading(false);
  }, []);

  const signOut = useCallback(async () => {
    apolloClient.clearStore();
    signOutOG({ callbackUrl: `${window.location.origin}/${locale}${getPaths.login()}` });
  }, [locale]);

  const onClickGoToMySport = useCallback(async () => {
    return user?.athlete?.sport_id
      ? push(getPaths.sport.id(user.athlete.sport_id))
      : push(getPaths.index.sections.sports.url());
  }, [user?.athlete?.sport_id]);

  useEffect(() => {
    if (!session?.accessToken) {
      setUser(undefined);
      setLoading(false);
      return () => null;
    }

    // we have a session
    updateUser();

    const timer = setInterval(() => {
      updateSession();
    }, JWT_REFRESH_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [session?.accessToken]);

  const value: UserContextValues = useMemo(() => {
    return {
      ...user,
      updateUser,
      signOut,
      loading,
      loggedIn: !!session?.accessToken && !!user?.user_id,
    };
  }, [
    user,
    session,
    signOut,
    loading,
    updateUser,
  ]);

  const userLoggedButNoSubscription = useMemo(() => {
    return value.loggedIn && !user?.hasAccess;
  }, [user, value]);

  return (
    <Provider value={value}>
      {value.loading
        ? <LoadingPage />
        : userLoggedButNoSubscription && window.location.pathname.includes("/athlete")
          ? (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100vh"
              gap={8}
              p={12}
              textAlign="center"
            >
              <Image
                // eslint-disable-next-line i18next/no-literal-string
                alt="myHeart logo"
                src={LINKS.images.bigLogo}
                h="240px"
                objectFit="contain"
                objectPosition="left"
                mb={8}
              />
              <Text>
                {t("contexts.useUser.noAccessTitle")}
              </Text>
              <Text>
                {t("contexts.useUser.noAccessMessage", { email: LINKS.MyHeartEmail })}
              </Text>

              <Button
                colorScheme="brandOrange"
                fontSize="xl"
                px={12}
                py={8}
                isLoading={loading}
                onClick={onClickGoToMySport}
              >
                {t("contexts.useUser.goToMySport")}
              </Button>
              <Button
                colorScheme="brandOrange"
                px={12}
                py={6}
                isLoading={loading}
                onClick={signOut}
              >
                {t("contexts.useUser.signOutButton")}
              </Button>
            </Flex>
          )
          : children}
    </Provider>
  );
};

export const useUser = () => useContext(UserContext);
