{/* eslint-disable i18next/no-literal-string */}
const TableStyles = {
  baseStyle: {
    th: {
      fontFamily: "body",
    },
  },
};

export default TableStyles;
